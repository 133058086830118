﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class DeviceLocation {

    deviceId: ko.Observable<number> = ko.observable(0);
    facilityBeaconGroupId: ko.Observable<number> = ko.observable(0);
    zoneName: ko.Observable<string> = ko.observable('');
    placementDescription: ko.Observable<string> = ko.observable('');
    placementComment: ko.Observable<string> = ko.observable('');
    placementDeviceComment: ko.Observable<string> = ko.observable('');
    detectedTime: moment.Moment = ''.toMoment();
    //lostTime: moment.Moment = ''.toMoment();

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
            this.detectedTime = moment(data["detectedTime"]);
        }
        else {
            this.deviceId = ko.observable(0);
            this.zoneName = ko.observable('No Location');
            this.placementDescription = ko.observable('');
            this.placementComment = ko.observable('');
            this.placementDeviceComment = ko.observable('');
            this.detectedTime = common.toFacilityTime(moment().utc());
        }
    }

    displayDetectedTimestamp: ko.PureComputed<string> = ko.pureComputed<string>(() => `${this.detectedTime.format('M/D/YYYY')} <span style="white-space: nowrap;">${this.detectedTime.format('h:mm:ss A')}</span>`)

    displayPlacement: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        let displayStr = this.placementDescription();

        if (this.placementComment() != null) {
            if (displayStr.length > 0) {
                displayStr += " - ";
            }
            displayStr += this.placementComment();
        }

        if (this.placementDeviceComment() != null) {
            if (displayStr.length > 0) {
                displayStr += " - ";
            }
            displayStr += this.placementDeviceComment();
        }

        return displayStr;
    })

}
