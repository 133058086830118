﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel, DeviceType, EntityType } from "../Common"
import { ViewModelItem } from "../ViewModelItem"

const common: CommonViewModel = globalThis.DIG.Common

export class FirmwareVersion extends ViewModelItem {
    firmwareVersionId: ko.Observable<number> = ko.observable(0)
    deviceType: ko.Observable<DeviceType> = ko.observable(DeviceType.Unknown)
    firmwareType: ko.Observable<string> = ko.observable('')
    majorVersion: ko.Observable<number> = ko.observable(0)
    buildVersion: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable('')
    releaseNotes: ko.Observable<string> = ko.observable('')
    buildTimestamp: ko.Observable<moment.Moment> = ko.observable(null);
    isRelease: ko.Observable<boolean> = ko.observable(false)
    isRetired: ko.Observable<boolean> = ko.observable(false)
    fileData: ko.Observable<string> = ko.observable('')

    displayVersion: ko.Computed<string> = ko.computed(() => {
        return this.majorVersion() !== 0
            ? `v${this.majorVersion()}.${this.buildVersion()}`
            : '';
    });

    displayBuildDate: ko.Computed<string> = ko.computed(() => {
        return this.buildTimestamp() !== null
            ? moment(this.buildTimestamp()).format('M/D/YYYY h:mm:ss A')
            : '';
    });

    displayType: ko.PureComputed<string> = ko.pureComputed(() => {
        return common.customStrings.find(e => e.entityType == EntityType.Device && e.entityId == this.deviceType()).shortDescription;
    });

    downloadUrl: ko.Computed<string> = ko.computed(() => {
        return `/api/firmware/${this.firmwareVersionId()}/file`;
    });

    displayVersionAndDescription: ko.Computed<string> = ko.computed(() => {
        return this.displayVersion() + " - " + this.description();
    });

    constructor(data?: object, view?: object) {
        super()

        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('build time')
            };
        }

        this.mapConfig.ignore = ['data'];

        if (data) {
            mapping.fromJS(data, this.mapConfig, this);
        }

        this.isRelease.subscribe(newValue => {
            this.saveIsRelease();
        });
    };

    getDetail = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/firmware/${this.firmwareVersionId()}`,
                cache: false,
            })
                .done(data => {
                    this.setDetail(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('FirmwareVersion::getDetail', request, textStatus, error);
                    reject();
                });
        })
    };

    setDetail = (detail) => {
        mapping.fromJS(detail, this.mapConfig, this);
    };

    toFormData = (): object => common.objectToFormData({
        FirmwareVersionId: this.firmwareVersionId(),
        DeviceType: this.deviceType(),
        FirmwareType: this.firmwareType(),
        MajorVersion: this.majorVersion(),
        BuildVersion: this.buildVersion(),
        Description: this.description(),
        ReleaseNotes: this.releaseNotes(),
        Data: this.fileData(),
        BuildTimestamp: this.buildTimestamp(),
        IsRelease: this.isRelease(),
        IsRetired: this.isRetired()
    });

    deleteVersion = () => {
        $.ajax({
            url: `/api/firmware/${this.firmwareVersionId()}`,
            method: 'DELETE',
            cache: false
        })
            .done(results => {
                this.view?.removeVersion(this.firmwareVersionId());
            })
            .fail((request, textStatus, error) => {
                common.toast('error', `${this.description()} was not deleted!`, 'Error');
                console.error('FirmwareVersion::delete', request, textStatus, error);
            });
    }

    //
    // Interface/Abstract methods"
    //
    public compare(compareTo: any, sort: string, direction: number): number {
        let result = null;

        switch (sort.toLowerCase()) {
            case 'description':
                result = this.description().localeCompare(compareTo.description()) * direction;
                break;

            case 'device type':
                result = this.displayType().localeCompare(compareTo.displayType()) * direction;
                break;

            case 'firmware type':
                result = this.firmwareType().localeCompare(compareTo.firmwareType()) * direction;
                break;

            case 'release':
                const aBool: number = this.isRelease() ? 1 : 0;
                const bBool: number = compareTo.isRelease() ? 1 : 0;
                result = (aBool - bBool) * direction;
                break;

            case 'build time':
                const aDate: any = moment(this.buildTimestamp());
                const bDate: any = moment(compareTo.buildTimestamp());
                result = (aDate - bDate) * direction;
                break;

            case 'version':
                result = (this.majorVersion() - compareTo.majorVersion()) * direction;
                if (result === 0) {
                    result = (this.buildVersion() - compareTo.buildVersion()) * direction;
                }
                break;
        }

        return result;
    }
   
    public isMatch(filter: string): boolean {
        let result = true;

        if (filter !== '') {
            const regex = new RegExp(filter, 'i');
            result = regex.test(this.description());
        }

        return result;
    }

    public getId = (): number => this.firmwareVersionId();
    public itemDescription = (): string => this.description();

    public selected(): void {
        //Nothing to do
    }

    static getById = async (firmwareVersionId: number): Promise<FirmwareVersion> => {
        return new Promise((resolve) => {
            const result = new FirmwareVersion();
            result.firmwareVersionId(firmwareVersionId);
            result.getDetail().then(() => resolve(result));
        })
    }

    public saveIsRelease(): Promise<boolean> {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: `/api/firmware/${this.firmwareVersionId()}/isRelease`,
                method: 'POST',
                data: {
                    isRelease: this.isRelease()
                },
                cache: false,
            })
                .done(results => {

                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Firmware::toggleIsRelease()", request, textStatus, error);
                    reject();
                })


        });
    }
}
