﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { ViewModelItem } from '../ViewModelItem'
import { EventTypeEnum } from "../EventTypes"
import { Dialogs } from "../Dialogs"
import { DeviceCurrentStatusType } from "../Common"

const common = globalThis.DIG.Common;

export class Event extends ViewModelItem {
    eventId = 0
    deviceId = 0
    eventType = 0
    gatewareRssi = 0
    loraChannel = ''
    sTimer = 0
    inmateId = 0
    inmateIdentifier = ''
    inmateFirstName = ''
    inmateLastName = ''
    eventTimestamp = ''
    storedTimestamp = ''
    isAlarmEvent = false
    isClearEvent = false
    isInfoEvent = false
    isEthernet = false
    eventData = []
    deviceStatusList = []

    constructor(data?, view?) {
        super()

        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('Event Timestamp')
            };
        }

        //'inmateIdentifier', 
        this.mapConfig.copy = ['eventId', 'deviceId', 'frameCounter', 'currentStatus', 'gatewayRssi', 'eventType', 'loraChannel', 'inmateId', 'inmateFirstName', 'inmateLastName', 'eventTimestamp', 'storedTimestamp', 'isAlarmEvent', 'isClearEvent', 'isInfoEvent', 'isEthernet', 'eventData', 'sTimer', 'deviceStatusList'];

        if (data) {
            this.setDetail(data);
        }
    }

    displayNamePlain: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        return this.view.sort() === 'First Name'
            ? `${this.inmateFirstName} ${this.inmateLastName}`
            : `${this.inmateLastName}, ${this.inmateFirstName}`;
    })

    displayName: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        return `${this.displayNamePlain()}`;
    })

    displayEventTime: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        const time = common.toFacilityTime(this.eventTimestamp);
        return `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`;
    })

    displayEventTimePlain: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        const time = common.toFacilityTime(this.eventTimestamp);
        return time.format('M/D/YYYY h:mm:ss A');
    })

    displayStoredTime: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        const time = common.toFacilityTime(this.storedTimestamp);
        return `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`;
    })

    displayStoredTimePlain: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        const time = common.toFacilityTime(this.storedTimestamp);
        return time.format('M/D/YYYY h:mm:ss A');
    })

    displayStoredTimeDiff: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        const time = common.toFacilityTime(this.storedTimestamp);
        const diff = moment(this.storedTimestamp).diff(moment(this.eventTimestamp), 's', true).toFixed(2);

        return `${time.format('M/D/YYYY h:mm:ss A')} (${diff}s)`;
    })

    displayTimeDiff: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        const diff = moment(this.storedTimestamp).diff(moment(this.eventTimestamp), 's', true).toFixed(2);
        return `${diff}s`;
    })

    displaySTimer: ko.PureComputed<string> = ko.pureComputed<string>(() => this.sTimer.toLocaleString('en-US'))

    displayChannel: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        return this.isEthernet ? "Ethernet" : this.loraChannel;
    })

    displayRssi: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        return this.isEthernet ? "N/A" : this.gatewareRssi.toString();
    })

    eventTypeDescription: ko.PureComputed<string> = ko.pureComputed<string>(() => this.eventType != 0
        ? common.getEventTypeById(this.eventType).description
        : '');

    is = (eventType: EventTypeEnum): boolean => this.eventType === eventType;

    typeIcon: ko.PureComputed<string> = ko.pureComputed(() => {
        return this.isAlarmEvent
            ? 'fas fa-bell-school alarm-icon'
            : this.isClearEvent
                ? 'fal fa-bell-school-slash'
                : 'fal fa-info';
    })

    flash: ko.Observable<boolean> = ko.observable<boolean>(false)

    displayClass: ko.Computed<string> = ko.computed<string>(() => this.flash() ? 'flash-row' : '')

    setDetail = (detail) => {
        mapping.fromJS(detail, this.mapConfig, this);
    };

    public compare(compareTo: any, sort: string, direction: number): number {
        let result = null;
        let aDate, bDate, aBool, bBool;

        switch (sort.toLowerCase()) {
            case 'device':
                result = (this.deviceId - compareTo.deviceId) * direction;
                break;

            case 'inmate':
            case 'last name':
                result = this.inmateLastName.localeCompare(compareTo.inmateLastName) * direction;
                if (result === 0) {
                    result = this.inmateFirstName.localeCompare(compareTo.inmateFirstName) * direction;
                }
                break;

            case 'first name':
                result = this.inmateFirstName.localeCompare(compareTo.inmateFirstName) * direction;
                if (result === 0) {
                    result = this.inmateLastName.localeCompare(compareTo.inmateLastName) * direction;
                }
                break;

            case 'event timestamp':
                aDate = moment(this.eventTimestamp);
                bDate = moment(compareTo.eventTimestamp);
                result = (aDate - bDate) * direction;
                break;

            case 'stored timestamp':
                aDate = moment(this.storedTimestamp);
                bDate = moment(compareTo.storedTimestamp);
                result = (aDate - bDate) * direction;
                break;

            case 'stimer':
                result = (this.sTimer - compareTo.sTimer) * direction;
                break;

            case 'event type':
                result = this.eventTypeDescription().localeCompare(compareTo.eventTypeDescription()) * direction;
                break;

            case 'channel':
                result = this.loraChannel.localeCompare(compareTo.loraChannel) * direction;
                break;

            case 'gw rssi':
                result = (this.gatewareRssi - compareTo.gatewayRssi) * direction;
                break;

            case 'category':
                aBool = this.isClearEvent ? 1 : 0;
                bBool = compareTo.isClearEvent ? 1 : 0;
                result = (aBool - bBool) * direction;
                break;
        }

        if (result === 0) {
            //
            //  Secondly, sort by eventId
            //
            result = (this.eventId - compareTo.eventId) * direction;
        }

        return result;
    }

    public itemDescription = (sort: string): string => {
        switch (sort.toLowerCase()) {
            case 'device': sort = 'deviceId'; break;
            case 'inmate': sort = 'displayNamePlain'; break;
            case 'last name': sort = 'displayNamePlain'; break;
            case 'event timestamp': sort = 'displayEventTimePlain'; break;
            case 'stored timestamp': sort = 'displayStoredTimePlain'; break;
            case 'stimer': sort = 'sTimer'; break;
            case 'event type': sort = 'eventTypeDescription'; break;
            case 'channel': sort = 'loraChannel'; break;
            case 'gw rssi': sort = 'gatewayRssi'; break;

            //  Please forgive this sin
            case 'category':
                return this.isAlarmEvent ? 'Alarm' : 'Information';
        }

        return typeof (this[sort]) === 'function'
            ? this[sort]()
            : this[sort]
    }

    public isMatch(filter: string, eventTypes?: string[]): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            }

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');
            const regexId = new RegExp('^\\D*' + text1, 'i');


            result = (regexName1.test(this.inmateFirstName) && regexName2.test(this.inmateLastName))
                || (regexName1.test(this.inmateLastName) && regexName2.test(this.inmateFirstName))
                || regexId.test(this.deviceId.toString());
        }

        if (result && (eventTypes ?? []).length > 0) {
            result = eventTypes.indexOf(this.eventType.toString()) > -1;
        }

        return result;
    }

    public statusDescription(status: DeviceCurrentStatusType): string {

        let result = "unknown";
        switch (status) {
            case DeviceCurrentStatusType.Unknown: result = "Unknown"; break;
            case DeviceCurrentStatusType.LowBattery: result = "Battery Low"; break;
            case DeviceCurrentStatusType.InRollCall: result = "In Roll Call"; break;
            case DeviceCurrentStatusType.StrapCurrentlyOpen: result = "Open Strap"; break;
            case DeviceCurrentStatusType.StrapAlarmInPastHour: result = "Recent Strap"; break;
            case DeviceCurrentStatusType.MissingConfigSettings: result = "Missing Config"; break;
            case DeviceCurrentStatusType.MissingSARSettings: result = "Missing SAR"; break;
            case DeviceCurrentStatusType.MissingRollCallSettings: result = "Missing Roll"; break;
            case DeviceCurrentStatusType.STimerOverflow: result = "STimer Overflow"; break;
            case DeviceCurrentStatusType.NoEthernetTxRx: result = "No Ethernet TxRx"; break;
        }
        return result;
    }

    public editInmate = (e) => {
        Dialogs.editInmate(e.inmateId);
    };

    public editDevice = (e) => {
        Dialogs.editDevice(e.deviceId);
    };

    public getId = (): number => this.eventId;
}